<template>
    <div>
        <div class="form-row" v-if="!getCustomerAmenities.length">
            <div class="bg-red-100 p-4 text-xs w-full text-red-500">
                <icon name="info" class="h-6 w-6 inline mr-4"></icon>You do not have any amenity added yet.
            </div>
        </div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label for="headline">section title</label>
                <text-input
                  id="headline"
                  v-model="amenities.headline"
                />
                <span v-if="hasError('amenities.headline')" class="form-error">{{ showError('amenities.headline') }}</span>

                <label for="description" class="mt-8">description</label>
                <text-input
                  id="description"
                  v-model="amenities.description"
                  multiline
                />
                <span v-if="hasError('amenities.description')" class="form-error">{{ showError('amenities.description') }}</span>

                <label class="mt-8" for="buttonText">button text</label>
                <text-input
                  id="buttonText"
                  v-model="amenities.buttonText"
                />

                <label class="mt-8 w-full">button link</label>
                <url-input v-model="amenities.buttonLink" :website-id="websiteId"></url-input>
            </div>

            <div class="form-col">
                <label>featured amenities</label>
                <div class="grid grid-cols-2 gap-4 pb-8">
                    <div>
                        <div class="text-sm text-highlight-textSmall font-500 mb-3">community</div>
                        <ul class="mb-4 pb-4 flex flex-wrap relative">
                            <transition-group name="list-complete" mode="in-out" class="space-y-2 block w-full">
                                <li class="bg-highlight-200 border border-highlight-300 tracking-wide text-xs font-500 py-2 px-4 text-highlight-textSmall cursor-pointer flex items-center justify-between featured-item"
                                    v-for="(featured) in featuredCommunityAmenities"
                                    @click="remove(featured.amenity)"
                                    :key="featured.amenity">
                                    <div class="selected-amenity-title">{{ featured.amenity }}</div>
                                    <span class="inline-block ml-4">
                                        <icon name="close" class="w-2 h-2"></icon>
                                    </span>
                                </li>
                            </transition-group>
                        </ul>
                    </div>
                    <div>
                        <div class="text-sm text-highlight-textSmall font-500 mb-3">apartment</div>
                        <ul class="mb-4 pb-4 flex flex-wrap relative">
                            <transition-group name="list-complete" mode="in-out" class="space-y-2 block w-full">
                                <li class="bg-highlight-200 border border-highlight-300 tracking-wide text-xs font-500 py-2 px-4 text-highlight-textSmall cursor-pointer flex items-center justify-between featured-item"
                                    v-for="(featured) in featuredApartmentAmenities"
                                    @click="remove(featured.amenity)"
                                    :key="featured.amenity">
                                    <div class="selected-amenity-title">{{ featured.amenity }}</div>
                                    <span class="inline-block ml-4">
                                        <icon name="close" class="w-2 h-2"></icon>
                                    </span>
                                </li>
                            </transition-group>
                        </ul>
                    </div>
                </div>
                <!-- Selected Featured Amenities -->

                <ul v-if="amenities.featuredAmenities.length <= 5" class="mt-4">
                    <li class="text-highlight-textSmall font-frank font-500 text-xs border-b border-gray-300 mb-4 pb-4 flex items-center">
                        <span>
                            <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        </span>
                        <span>please select up to 6 amenities from the list below</span>
                    </li>
                </ul>
                <ul v-if="amenities.featuredAmenities.length > 5" class="mt-4">
                    <li class="text-highlight-textSmall font-frank font-500 text-xs border-b border-gray-300 mb-4 pb-4 flex items-center">
                        <span class="font-bold text-highlight-base font-mono text-lg">
                            <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        </span>
                        <span class="text-highlight-textSmall font-frank text-xs font-600">maximum of 6 amenities are selected</span>
                    </li>
                </ul>
                <!-- Full List of Amenities -->
                <div class="amenities-list">

                    <div>
                        <label>community amenities</label>
                        <ul>
                            <li class="cursor-pointer" v-for="(item, idx) in sortedCommunityAmenities"
                                :data-value="item.amenity"
                                @click="add(item, idx)"
                                :class="{'selected' : amenities.featuredAmenities.find(amenity => amenity.amenity === item.amenity)}"
                                :key="item.amenity">
                                {{ item.amenity }}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <label>apartment amenities</label>
                        <ul>
                            <li class="cursor-pointer" v-for="(item, idx) in sortedApartmentAmenities"
                                :data-value="item.amenity"
                                @click="add(item, idx)"
                                :class="{'selected' : amenities.featuredAmenities.find(amenity => amenity.amenity === item.amenity)}"
                                :key="item.amenity">
                                {{ item.amenity }}
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- <span class="form-hint mb-4">Select up to {{ nbFeatured }} amenities.</span> -->
                <span v-if="hasError('amenities.featuredAmenities')" class="form-error">{{ showError('amenities.featuredAmenities') }}</span>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";
import Icon from "@/components/ui/Icon";
import UrlInput from "@/components/cms/UrlInput";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInput from "@/components/ui/TextInput";

export default {
    mixins: [ ModalNavigation, Widget ],
    components: {ModalFooter, UrlInput, Icon, TextInput},
    emits: ['save-page-widget'],
    data: () => {
        return {
            amenities: {
                headline: null,
                description: null,
                buttonLink: null,
                buttonText: null,
                featuredAmenities: [],
            },
            nbFeatured: 6,
            websiteId: null,
            isProcessing: false,
            websiteInfo: [],
            communityAmenities: [],
        }
    },
    computed: {
        ...mapGetters({
            getCustomerAmenities: 'amenities/getCustomerAmenities',
            getCustomerCommunityAmenitiesByType: 'amenities/getCustomerCommunityAmenitiesByType'
        }),
        communityAmenitiesList() {
            const communityAmenitiesType = this.communityAmenities.filter(x => x.amenity_details.community_amenities_type_name == 'Community').map(x =>({
                amenity : x.customer_community_amenities_name, 
                icon : x.amenity_details.amenity_icons_name,
                order : x.order, 
                type : x.amenity_details.community_amenities_type_name}));

            return communityAmenitiesType
        },
        sortedCommunityAmenities: function() {
            let items = this.communityAmenitiesList
            return items
        },
        apartmentAmenitiesList() {
            const apartmentAmenitiesType = this.communityAmenities.filter(x => x.amenity_details.community_amenities_type_name == 'Apartment').map(x =>({
                amenity : x.customer_community_amenities_name, 
                icon : x.amenity_details.amenity_icons_name,
                order : x.order, 
                type : x.amenity_details.community_amenities_type_name}));            

            return apartmentAmenitiesType
        },
        sortedApartmentAmenities: function() {
            let items = this.apartmentAmenitiesList
            return items
        },
        featuredCommunityAmenities() {
            return this.amenities.featuredAmenities.filter((amenity) => {
                return amenity.type === 'Community'
            })
        },
        featuredApartmentAmenities() {
            return this.amenities.featuredAmenities.filter((amenity) => {
                return amenity.type === 'Apartment'
            })
        }
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.isProcessing = true;

                this.$emit('save-page-widget', {
                    headline: this.amenities.headline,
                    description: this.amenities.description,
                    buttonText: this.amenities.buttonText,
                    buttonLink: this.amenities.buttonLink,
                    amenities: JSON.stringify(this.amenities.featuredAmenities)
                });
            }
        },
        add(item) {
            if( this.amenities.featuredAmenities.length <= 5 && !this.amenities.featuredAmenities.find(amenity => amenity.amenity === item.amenity)) {
                this.amenities.featuredAmenities.push(item);
            }
        },
        remove(key) {
            this.amenities.featuredAmenities.splice(
                this.amenities.featuredAmenities.findIndex(amenity => amenity.amenity === key), 1
            );
        },
        async gettingAmenities () {
            await this.$cmsDataProvider.get('customerCommunityAmenities', { communityId: this.websiteInfo.communities_id })
                .then(response => {

                    this.communityAmenities = response
                })
                .catch((err) => this.notifyError(err));
        },
        async getWebsiteInfo() {
            await this.$cmsDataProvider.get('websitebyId', {websiteId: this.$route.params.websiteId})
            .then((response) => {
                if(!response.message) {
                    this.websiteInfo = response
                }else{
                    this.notifyError('Website not found');
                }
            }).catch(() => {
                this.notifyError('There was an error getting the website ');
                this.website = null
            });
            await this.gettingAmenities();
        }             

    },
    validations: {
        'amenities.headline' : 'required',
        'amenities.description' : 'required',
        'amenities.featuredAmenities': 'required | max:nbFeatured'
    },
    created() {
        this.initValidator();
        this.websiteId = this.$route.params.websiteId;

        if(this.values.length) {
            this.featuredAmenities = JSON.parse(this.getValue('amenities'));

            Object.assign(this.amenities, {
                headline: this.getValue('headline'),
                description: this.getValue('description'),
                buttonLink: this.getValue('buttonLink'),
                buttonText: this.getValue('buttonText'),
                featuredAmenities: this.featuredAmenities
            });
        }
    },
    async mounted() {
        await this.getWebsiteInfo();
        
    }
}
</script>
<style scoped>
    .amenities-list{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1.5rem;
        height: 25vh;
        min-height: 200px;
        overflow: auto;
        font-size: .875rem;
        line-height: 2;
    }
    .amenities-list li{
        max-width: 100%;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 1rem;
        padding-bottom: 1rem;
        line-height: 1;
    }
    .selected {
        color: #ccc;
        text-decoration: line-through;
    }
    .selected-amenity-title{
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 1.5rem);
        overflow: hidden;
    }
    .featured-item {
    transition: all .35s;
    }
    .list-complete-enter-from, .list-complete-leave-to{
    opacity: 0;
    transform: translateY(5px);
    }
    .list-complete-leave-active {
    position: absolute;
    white-space: pre;
    }

    .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
